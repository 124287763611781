import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Homepage from "../../src/views/homepage/homepage";
import Header from "../component/common/header/header";
import Footer from "../component/common/footer/footer";
import Contact from "../views/contact/contact";
import Services from "../views/services/services";
import ServiceDetails from "../views/services/service-details";
import News from "../views/news/news";
import NewsDetails from "../views/news/news-details";
import Gallery from "../views/gallery/gallery";
import WhoWeAre from "../views/about/who-we-are";
import WorkWithUs from "../views/project/work-with-us";

export default function PageRoutes() {
    return (
        <div className="wsmenucontainer">
            <div id="page" className="page">
                <Routers>
                    <Header/>
                    <Routes>
                        <Route path={"/"} element={<Homepage/>} />
                        <Route path={"/contact"} element={<Contact/>} />
                        <Route path={"/our-services"} element={<Services/>} />
                        <Route path={"/service/:slug"} element={<ServiceDetails/>} />
                        <Route path={"/news"} element={<News/>} />
                        <Route path={"/news/:slug"} element={<NewsDetails/>} />
                        <Route path={"/gallery"} element={<Gallery/>} />
                        <Route path={"/who-we-are"} element={<WhoWeAre/>} />
                        <Route path={"/work-with-us"} element={<WorkWithUs/>} />
                        <Route path={"/book-appointment"} element={<div className="text-center alert alert-info d-flex justify-content-center" style={{marginTop: '80px'}}><h1>UNDER CONSTRUCTION</h1></div>}/>
                        <Route path={"/project"} element={<div className="text-center alert alert-info" style={{marginTop: '80px'}}><h1>COMING SOON</h1></div>}/>

                        {/*Application End*/}
                        <Route path={"*"} element={<h1>Hello World</h1>} />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    {/* Same as */}
                    <ToastContainer />
                    <Footer/>
                </Routers>
            </div>
        </div>
    );
}